<template>
    <div>
        <title-nav :title="'积分明细'"/>
        <div class="member-points">
            <div class="member-points-header-total-value">{{ totalPoints }}</div>
            <div class="member-points-list">
              <div v-for="(item, index) in points" :key="index" class="member-points-list-item">
                <div class="member-points-list-item-type">{{ item.desc }}</div>
                <div class="member-points-list-item-date">{{ item.createdAt }}</div>
                <div class="member-points-list-item-points">{{ item.amount }}</div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryPointsDetailById } from '@/api/api'
import TitleNav from '../../components/nav/TitleNav.vue'

export default {
    name: 'PointDetail',
    components: {
        TitleNav
    },
    created(){
    },
    mounted(){
        this.getData()
    },
    props:{

    },
    data:()=>{
        return {
            totalPoints: '',
            points:[]
        }
    },
    methods:{
      getData(){
        let uidTemp = JSON.parse(localStorage.getItem('userInfo'))[0].uid
        queryPointsDetailById({ limit: 100, uid: uidTemp }).then(res => {
          this.points = res.data.data
        })
        this.totalPoints = this.$route.query.member_point
      }
    },
}
</script>

<style scoped lang="scss">
.member-points {
    background-color: #fff;
    margin: 60px 10px 10px;
    padding: 5px 0 10px;
    border-radius: 10px;
}

.member-points-header-total-value {
  font-size: 50px;
  color: #f60;
  text-align: left;
  padding: 5px 20px;
  background: #fff;
}

.member-points-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 0px;
  margin: 0 20px;
  border-bottom: 1px solid #f7f7f7;
}

.member-points-list-item-type {
  font-size: 14px;
  color: #666;
}

.member-points-list-item-date {
  font-size: 14px;
  color: #999;
}

.member-points-list-item-points {
  font-size: 16px;
  color: #666;
}
</style>